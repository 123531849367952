












































































import { Vue, Component } from 'vue-property-decorator'
import CountrySupport from './component/CountrySupport.vue'
import CommonTitle from './component/CommonTitle.vue'

interface IImgUrls {
    [key: string]: string
}
@Component({
    name: 'OurBusiness',
    components: {
        CountrySupport,
        CommonTitle,
    }
})
export default class extends Vue {
    imgUrls: IImgUrls = {
        'Singapore': require('@/assets/images/flag_SG.png'),
        'Hongkong': require('@/assets/images/flag_HK.png'),
        'Taiwan': require('@/assets/images/flag_TW.png'),
        'Malaysia': require('@/assets/images/flag_MY.png'),
        'China': require('@/assets/images/flag_CH.png'),
        'Japan': require('@/assets/images/flag_Japan.png'),
    }
    bannerList: any = [
        require('@/assets/images/ourBusiness_map.png'),

    ]
}
